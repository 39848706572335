.list {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.list li {
  width: 100%;
  border-radius: 5px;
}

.list li + li {
  margin-top: 1rem;
}

.listItem {
  width: 100%;
  outline: none;
  cursor: pointer;
  overflow: hidden;
  border: 2px solid #212121;
  background: linear-gradient(#1f2124, #17181c);
}

.listItem:focus-visible {
  border: 2px solid #3dafd8;
}

.transparentListItem {
  width: 100%;
  overflow: hidden;
}

.listItemContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
}

.description > div {
  background-color: #282c34;
  width: 14rem;
  border-radius: 6px;
  height: 0.5rem;
}

.description > div:nth-of-type(2) {
  width: 11rem;
}

.description > div:nth-of-type(3) {
  width: 8rem;
}

.description > div + div {
  margin-top: 1rem;
}

.description {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.expandedListItem .description {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.expandedListItem {
  outline: none;
  cursor: pointer;
  border: 2px solid #212121;
  background: linear-gradient(#1f2124, #17181c);
}

.expandedListItem:focus-visible {
  border: 2px solid #3dafd8;
}

.expandedListItemContent {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.additional-content {
  width: 100%;
  margin-top: 2rem;
}

.additional-content > div {
  opacity: 0;
  border-radius: 3px;
  background-color: #121212;
  border: 0.5px solid #212121;
}

/* content fade in animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
  }
}

.animated-in .additional-content > div {
  animation: fadeIn 0.4s forwards;
}

.additional-content > div:nth-of-type(2) {
  animation-delay: 0.15s;
}
.additional-content > div:nth-of-type(3) {
  animation-delay: 0.3s;
}
.additional-content > div + div {
  margin-top: 1rem;
}
