@import "~normalize.css";
@import url('https://fonts.googleapis.com/css?family=Karla:400,500,700');
* {
  box-sizing: border-box;
}

:root {
  --white: #fff;
  --grey: #f1f4f8b0;
  --dark-grey: #6b7c93;
  --green: #24b47e;
  --teal: #4F96CE;
  --blue: #6772e5;
  --black: #000;
  --gold: #d4af37;
  --dark-blue: #4F3EF5;
  --spacer: 28px;
}

body {
  font-family: karla, -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  color: var(--dark-grey);
  background: #16151A;
}

a {
  text-decoration: none;
  color: var(--black);
}

ul {
  padding-left: 0;
}

a:hover,
a:focus {
  color: var(--dark-grey);
}

/* START HAMBURGER MENU*/
#page-wrap {
  text-align: center;
  /* Prevent sidebar from showing a scrollbar on page */
  overflow: auto;
}

/* Individual item */
.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: white;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 26px;
  height: 20px;
  right: 14px;
  top: 14px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #222;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 26px;
  width: 26px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #F5F5F5;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #F5F5F5;
}

/* Wrapper for item list */
.bm-item-list {
  overflow-y: hidden;
  color: rgb(5, 15, 25);
  overflow-y: hidden !important;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}


/* END HAMBURGER MENU */

.Toastify__toast.Toastify__toast--default {
  background: #222;
}

.Toastify__toast-body {
  color: #FFF;
  font-weight: bold;
  text-align: center;
}