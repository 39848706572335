@import url(https://fonts.googleapis.com/css?family=Karla:400,500,700);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css);
* {
  box-sizing: border-box;
}

:root {
  --white: #fff;
  --grey: #f1f4f8b0;
  --dark-grey: #6b7c93;
  --green: #24b47e;
  --teal: #4F96CE;
  --blue: #6772e5;
  --black: #000;
  --gold: #d4af37;
  --dark-blue: #4F3EF5;
  --spacer: 28px;
}

body {
  font-family: karla, -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #6b7c93;
  color: var(--dark-grey);
  background: #16151A;
}

a {
  text-decoration: none;
  color: #000;
  color: var(--black);
}

ul {
  padding-left: 0;
}

a:hover,
a:focus {
  color: #6b7c93;
  color: var(--dark-grey);
}

/* START HAMBURGER MENU*/
#page-wrap {
  text-align: center;
  /* Prevent sidebar from showing a scrollbar on page */
  overflow: auto;
}

/* Individual item */
.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: white;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 26px;
  height: 20px;
  right: 14px;
  top: 14px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #222;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 26px;
  width: 26px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #F5F5F5;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #F5F5F5;
}

/* Wrapper for item list */
.bm-item-list {
  overflow-y: hidden;
  color: rgb(5, 15, 25);
  overflow-y: hidden !important;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}


/* END HAMBURGER MENU */

.Toastify__toast.Toastify__toast--default {
  background: #222;
}

.Toastify__toast-body {
  color: #FFF;
  font-weight: bold;
  text-align: center;
}
.list {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.list li {
  width: 100%;
  border-radius: 5px;
}

.list li + li {
  margin-top: 1rem;
}

.listItem {
  width: 100%;
  outline: none;
  cursor: pointer;
  overflow: hidden;
  border: 2px solid #212121;
  background: linear-gradient(#1f2124, #17181c);
}

.listItem:focus-visible {
  border: 2px solid #3dafd8;
}

.transparentListItem {
  width: 100%;
  overflow: hidden;
}

.listItemContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
}

.description > div {
  background-color: #282c34;
  width: 14rem;
  border-radius: 6px;
  height: 0.5rem;
}

.description > div:nth-of-type(2) {
  width: 11rem;
}

.description > div:nth-of-type(3) {
  width: 8rem;
}

.description > div + div {
  margin-top: 1rem;
}

.description {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.expandedListItem .description {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.expandedListItem {
  outline: none;
  cursor: pointer;
  border: 2px solid #212121;
  background: linear-gradient(#1f2124, #17181c);
}

.expandedListItem:focus-visible {
  border: 2px solid #3dafd8;
}

.expandedListItemContent {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.additional-content {
  width: 100%;
  margin-top: 2rem;
}

.additional-content > div {
  opacity: 0;
  border-radius: 3px;
  background-color: #121212;
  border: 0.5px solid #212121;
}

/* content fade in animations */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
  }
}

.animated-in .additional-content > div {
  -webkit-animation: fadeIn 0.4s forwards;
          animation: fadeIn 0.4s forwards;
}

.additional-content > div:nth-of-type(2) {
  -webkit-animation-delay: 0.15s;
          animation-delay: 0.15s;
}
.additional-content > div:nth-of-type(3) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.additional-content > div + div {
  margin-top: 1rem;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.-zero-index {
  z-index: 0;
}

.rt-td {
  display: flex;
  align-items: center;
}

.rt-tr {
  height: 1.85rem;
}

.rt-th {
  background: linear-gradient(#1f2124, #17181c);
}

.rt-td {
  background: linear-gradient(#1f2124, #17181c);
}

.-striped {
  /* background: #000 !important; */
}

.social-container {
  text-align: center;
}

.social-icons {
  padding: 0;
  list-style: none;
  /* margin: 1rem; */
}

.social-icons li {
  display: inline-block;
  margin: 0 2rem;
  position: relative;
  font-size: 1.2rem;
}

.social-icons i {
  color: #fff;
  position: absolute;
  top: 14px;
  left: 14px;
  transition: all 265ms ease-out;
}

.social-icons a {
  display: inline-block;
}

.social-icons a:before {
  transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  content: " ";
  width: 46px;
  height: 46px;
  border-radius: 100%;
  display: block;
  background: linear-gradient(45deg, #57F2F9, #2266B4);
  transition: all 265ms ease-out;
}

.social-icons a:hover:before {
  transform: scale(0);
  transition: all 265ms ease-in;
}

.social-icons a:hover i {
  transform: scale(2);
  -ms-transform: scale(2);
  -webkit-transform: scale(2);
  color: #57F2F9;
  background: -webkit-linear-gradient(45deg, #57F2F9, #2266B4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 265ms ease-in;
}

@media (max-width: 768px) {
  .social-icons li {
    margin: 0 1rem;
  }
}

@media (max-width: 480px) {
  .social-icons li {
    margin: 0 0.2rem;
  }
}

