@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css);

.social-container {
  text-align: center;
}

.social-icons {
  padding: 0;
  list-style: none;
  /* margin: 1rem; */
}

.social-icons li {
  display: inline-block;
  margin: 0 2rem;
  position: relative;
  font-size: 1.2rem;
}

.social-icons i {
  color: #fff;
  position: absolute;
  top: 14px;
  left: 14px;
  transition: all 265ms ease-out;
}

.social-icons a {
  display: inline-block;
}

.social-icons a:before {
  transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  content: " ";
  width: 46px;
  height: 46px;
  border-radius: 100%;
  display: block;
  background: linear-gradient(45deg, #57F2F9, #2266B4);
  transition: all 265ms ease-out;
}

.social-icons a:hover:before {
  transform: scale(0);
  transition: all 265ms ease-in;
}

.social-icons a:hover i {
  transform: scale(2);
  -ms-transform: scale(2);
  -webkit-transform: scale(2);
  color: #57F2F9;
  background: -webkit-linear-gradient(45deg, #57F2F9, #2266B4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 265ms ease-in;
}

@media (max-width: 768px) {
  .social-icons li {
    margin: 0 1rem;
  }
}

@media (max-width: 480px) {
  .social-icons li {
    margin: 0 0.2rem;
  }
}
