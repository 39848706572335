.-zero-index {
  z-index: 0;
}

.rt-td {
  display: flex;
  align-items: center;
}

.rt-tr {
  height: 1.85rem;
}

.rt-th {
  background: linear-gradient(#1f2124, #17181c);
}

.rt-td {
  background: linear-gradient(#1f2124, #17181c);
}

.-striped {
  /* background: #000 !important; */
}
